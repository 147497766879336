<template>
    <div class="con-info">
        <div class="ns-info">
        <SyaratAda/>
        </div>
    </div>
  </template>
  <script>
  import SyaratAda from '@/components/Syarat.vue'
  export default {
    components: {
      // Popup,
      SyaratAda,
    },
    data() {
      return {
        
      };
    },
    computed: {
      
    },
    mounted() {
      
    },
  };
  </script>
  <style>
  </style>
  <style scoped>
  .con-info{
    background: #002b51;
    background: linear-gradient(180deg,#0060a8,#04002c);
    padding: 20px;
    margin: 0px;
    height: 100vh;
  }
  .ns-info{
    margin-top: 1.5rem;
    color: #fff !important;
    background-image: url("../assets/bg-p.png");
    box-shadow: inset #00d1c4 0px 0px 6px 4px;
    background-repeat: repeat;
    border-radius: 25px;
    overflow: scroll;
    height: 87vh;
    padding: 30px;
  }
  </style>
  